import React from "react"
import { navigate } from "gatsby"
import SEO from "../components/seo"
import { isLoggedIn } from "../services/auth"
import GeneratePassword from "../components/user/generatePassword"
import { REGISTRATION_TYPE_NONE } from "../utils/constants"
import "../wdyr"
const GeneratePasswordPage = () => {
  const isBrowser = () => typeof window !== "undefined"
  if (isBrowser() && isLoggedIn()) {
    navigate(`/dashboard`)
    return false
  }
  if (isBrowser() && process.env.REGISTRATION_TYPE === REGISTRATION_TYPE_NONE) {
    navigate(`/404`)
    return false
  }
  return (
    <>
      <SEO title="GeneratePassword" />
      <GeneratePassword />
    </>
  )
}

export default GeneratePasswordPage
